<template>
  <div class="integral-detail-con">
    <!-- <NavBar fiexd left-arrow @click-left="goBack" v-if="isWeixin_status == false"></NavBar> -->
    <swipe :autoplay="3000" indicator-color="white" :height="375">
      <swipe-item v-for="image in picArr" :key="image">
        <img width="100%" height="100%" fit="cover" v-lazy="image" />
      </swipe-item>
    </swipe>
    <div class="detail-title">
      <div class="detail-title-top">
        <span>{{ InfoObj.title }}</span>
        <!-- <span>近期已兑换{{InfoObj.conversion_num}}个</span> -->
      </div>
      <div class="detail-title-bottom">
        <span v-if="InfoObj.buy_method == 1">{{InfoObj.credit}}积分</span>
        <span v-else-if="InfoObj.buy_method == 2">{{InfoObj.credit}}积分,{{InfoObj.money}}元</span>
        <span v-else-if="InfoObj.buy_method == 3">{{InfoObj.money}}元</span>
        <span>库存{{ InfoObj.stock }}件</span>
      </div>
    </div>
    <Panel title="商品详情">
      <div v-html="InfoObj.brife" class="integral-brife"></div>
    </Panel>
    <div class="integral-btn" @click="show_make = true">
      <button class="button" style="vertical-align: middle">
        <span>立即{{ InfoObj.buy_method | update_text }} </span>
      </button>
    </div>
    <Popup v-model="show_make" position="bottom">
      <div class="exchange-bot">
        <div class="exchange-bot-left">
          <div>
            <img v-lazy="InfoObj.pic" alt="" />
          </div>
          <div>
            <span>{{ InfoObj.title }}</span>
            <span v-if="InfoObj.buy_method == 1">{{all_credit}}积分</span>
            <span v-else-if="InfoObj.buy_method == 2">{{all_credit}}积分,{{all_money}}元</span>
            <span v-else-if="InfoObj.buy_method == 3">{{all_money}}元</span>
          </div>
        </div>
        <Stepper v-model="buy_num" :min="1" :disable-input="true"></Stepper>
      </div>
      <div class="bot-btn">
        <Button block type="danger" @click="show_make = false">取消</Button>
        <Button block type="primary" :disabled="load_status" @click="make_order"
          >{{ InfoObj.buy_method | update_text }}</Button
        >
      </div>
    </Popup>
  </div>
</template>
<script>
import { Swipe, SwipeItem, Panel, Stepper, Popup, Button } from "vant";
import CreditShop from "@/api/creditshop";
import { wxPayTwo } from '@/utils/wxpay';

export default {
  components: {
    Swipe,
    SwipeItem,
    Panel,
    Stepper,
    Popup,
    Button,
  },
  filters: {
    update_text: function(val) {
      if (val == 3 || val == 2) return '购买'
      return '兑换'
    }
  },
  data() {
    return {
      images: [
        "https://img.yzcdn.cn/vant/apple-1.jpg",
        "https://img.iplaysoft.com/wp-content/uploads/2019/free-images/free_stock_photo.jpg",
      ],
      show_make: false,
      buy_num: 1,
      InfoObj: {},
      picArr: [],
      load_status: false,
      order_detail: {}
    };
  },
  computed: {
    all_credit: function() {
      return this.buy_num * this.InfoObj.credit;
    },
    all_money: function() {
      return this.buy_num * this.InfoObj.money;
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getInfoDetail(this.$route.query.id);
    }
  },
  methods: {
    async getInfoDetail(idx) {
      try {
        const res = await CreditShop.getCreditshopDetail(idx);
        if (res.code == 200) {
          this.InfoObj = res.data;
          this.picArr.push(res.data.pic);
        } else {
          throw res.msg;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async make_order() {
      try {
        this.load_status = true;
        const res = await CreditShop.pushOrder(this.InfoObj.id, this.buy_num);
        if (res.code == 200) {
          console.log(res.data.id)
          this.order_detail = res.data;
          const { total_credit, pay_status, total_money_cash } = res.data;
          this.show_make = false;
          this.$toast(res.msg);
          if (pay_status && Number(total_money_cash) == 0) {
            this.$toast('兑换成功!');
            setTimeout(() => {
              this.$router.push("/integral?type=1");
            }, 800);
          } else {
            this.load_status = false;
            let str = Number(total_credit) > 0 ? `消耗${total_credit}积分`:'';
            this.$dialog.confirm({
              title: "支付确认",
              message: `确认支付${str}${total_money_cash}元购买此商品`,
            }).then(() => {
              // 检查订单是否可以支付
              this.checkOrderPayStatus();
            })
          }
        } else {
          this.load_status = false;
          this.$toast(res.msg);
        }
      } catch (error) {
        this.$toast(error);
        this.load_status = false;
      }
    },
    // 检查订单是否可以支付
    async checkOrderPayStatus() {
      try {
        const res = await CreditShop.CheckShopOrder(this.order_detail.id);
        if (res.code == 200) {
          console.log(res)
          await this.payShoporder();
        } else {
          this.$toast(res.msg);
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 支付积分商品订单
    async payShoporder() {
      try {
        const res = await CreditShop.payShoporder(this.order_detail.id, this.isWeixin_status ? 'wx':'h5');
        if (res.code == 200) {
          console.log(res)
          await wxPayTwo(res.data);
        } else {
          this.$toast(res.msg);
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.integral-detail-con {
  width: 100%;
  height: 100vh;
  background: rgba(244, 244, 244, 1);
  overflow-y: scroll;
  .detail-title {
    width: 100%;
    // height: 100px;
    padding: 10px 4px 6px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
    .detail-title-top {
      width: 100%;
      height: 25px;
      font-size: 16px;
      display: flex;
      color: #333;
      padding-left: 10px;
      flex-direction: column;
      margin-bottom: 5px;
      span:nth-child(1) {
        font-size: 16px;
      }
      span:nth-child(2) {
        font-size: 12px;
        color: #dfdfdf;
      }
    }

    .detail-title-bottom {
      flex: 1;
      border-radius: 3px;
      padding: 0 10px;
      display: flex;
      font-size: 16px;
      align-items: center;
      justify-content: space-between;
      background: #ffffff;
      span:nth-child(1) {
        color: #ff5954;
      }
      span:nth-child(2) {
        color: #696969;
        font-size: 12px;
      }
    }
  }
  .integral-brife {
    padding: 10px;
  }
  .exchange-bot {
    width: 100%;
    height: 100px;
    align-items: center;
    display: flex;
    padding: 14px 10px 0 10px;
    justify-content: space-between;
    .exchange-bot-left {
      width: 70%;
      height: 90%;
      display: flex;
      padding-left: 10px;
      div:nth-child(1) {
        width: 60px;
        height: 60px;
        img {
          max-width: 100%;
          height: 100%;
        }
      }
      div:nth-child(2) {
        flex: 1;
        height: 60px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-left: 10px;
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
  .bot-btn {
    flex: 1;
    display: flex;
    z-index: 1000;
  }
  .integral-btn {
    width: 100%;
    height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;
    background: #ffffff;
    .button {
      width: 96%;
      height: 40px;
      display: inline-block;
      border-radius: 4px;
      background-color: #ff5954;
      border: none;
      color: #ffffff;
      text-align: center;
      font-size: 14px;
      transition: all 0.5s;
      cursor: pointer;
    }

    .button span {
      cursor: pointer;
      display: inline-block;
      position: relative;
      transition: 0.5s;
    }

    .button span:after {
      content: "»";
      position: absolute;
      opacity: 0;
      top: 1.8px;
      right: -20px;
      transition: 0.5s;
    }

    .button:hover span {
      padding-right: 25px;
    }

    .button:hover span:after {
      opacity: 1;
      right: 0;
    }
  }
}
</style>